/* IMAGE PRELOAD */
body:after{
    position:absolute; width:0; height:0; overflow:hidden; z-index:-1;
    content: url('../assets/images/library/add_collection.png')
             url('../assets/images/library/add_collection_hover.png')
             url('../assets/images/library/added_collection.png')
             url('../assets/images/library/added_collection_hover.png')
             url('../assets/images/fileTypes/active_icon_big.png')
             url('../assets/images/library/import.png')
             url('../assets/images/library/export.png')
             url('../assets/images/library/clear_collection.png')
             url('../assets/images/library/download_all.png');
}

/****************/

#notifications {
  position: fixed;
  left: 50%;
  top: 0;
  margin: 0;
  padding: 0;
  z-index: 9999;
  width: 360px;
  margin-left: -180px;

  .alert {
    display: none;
    width: 340px;
    margin: 10px auto 0;
    background: #669933;
    color: #fff;
    border-radius: 3px;
    text-align: center;
    padding: 5px 10px;
    box-shadow: 2px 2px 3px 1px rgba(0,0,0,0.4);

    &.success {
      background: #669933;
    }

    &.error {
      background: #ef0b0b;
    }

    &.info, &.warning {
      background: #f2af00;
    }

    &:first-child {
      margin-top: 25px;
    }
  }
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Chrome/Safari/Opera */
  -khtml-user-select: none;    /* Konqueror */
  -moz-user-select: none;      /* Firefox */
  -ms-user-select: none;       /* Internet Explorer/Edge */
  user-select: none;           /* Non-prefixed version, currently
                                  not supported by any browser */
}