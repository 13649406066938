/**
 * This file should be divided in its subsequent modules
 */
 @import 'variables';

.dellTitle {
    margin: 25px 0;
    text-align: center;
    color: #c92466;
    font-family: @secondaryFont500;
    font-size: 20px;
}
.app-modal-window .modal-dialog {
    width: 286px;
}
.app-modal-window-modalWindow .modal-dialog{
  width: 500px;
}
.app-modal-window-modalWindow .modal-content{
  float:left;
}
.app-modal-window-addUser .modal-dialog{
  width: 680px;
}
.app-modal-window-deleteUser .modal-dialog{
  width: 500px;
  height: 224px;
}
.app-modal-window-collection .modal-dialog{
  width: 1000px;
}

.app-modal-window-folder .modal-content{
height: 500px;
}

.app-modal-window-getContent .modal-dialog{
    margin-top: 70px;

    @media (min-width: 768px) {
      width: 875px;
    }
}
.app-modal-window-getContent .modal-content{
  height: 650px;
}
.app-modal-BackDropWindow {
    background: blue;
}
.modal-backdrop {
    background: white !important;
}
.modal-content {
    border-radius: 0 !important;
    box-shadow: 0 5px 15px rgba(152, 151, 152, 0.5) !important;
}
#forgotPasswordWrap {
    color: #6D6D6D;
    padding-bottom: 45px;
}
#forgotPasswordWrap span {
    float: right;
}
#forgotPasswordWrap span:hover {
    cursor: pointer;
}
#forgotPasswordWrap h3 {
    float: left;
    width: 100%;
    margin: 0;
    padding: 0;
    margin: 20px 0;
    display: block;
}
.smallFormDescription {
    font-size: smaller;
    display: block;
    margin: 15px 0;
}
.dellTile {
    background: #0685c2;
    color: white;
    text-align: center;
    width: 150px;
    border-radius: 10px;
    margin: auto;
    &:hover {
        background: #0d9ed3;
        cursor: pointer;
    }
    &:active {
        background: #0778a5;
    }
    img {
        margin: 40px 0 15px 0;
    }
}
.dellTab{
  padding: 0;
  width: 20%;
  float: left;
  border-bottom:solid 2px #CCCCCC;
  p{
      font-family: @secondaryFont500;
    font-size: 15px;
    color: white;
    float: right;
  }
  div{
      margin-left: 1px;
      padding: 1px;
    position: relative;
    text-align: center;
      background: #0685c2;
    width: 99.5%;
    height: 49px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    border: none !important;
      &:hover {
          background: #0d9ed3;
          cursor: pointer;
      }
    &:hover > div {
        background-size: 20px;
        }
      &:active {
          background: #0778a5;
      }
    div{
      height: 20px;
      background-size: 20px;
          position: absolute;
      border: none !important;
    }

  }
}
.imagesTab div{
  &:hover > div {
        background: url('../assets/images/admin/images.png') 0 -20px no-repeat;
      }
    &:active > div {
        background: url('../assets/images/admin/images.png') 0 0 no-repeat;
    }
  div{
    width: 83px;
    height: 20px;
    background: url('../assets/images/admin/images.png') 0 -40px no-repeat;
    top: 14px;
    left: 50px;
  }
}
.selectedTabImages{
  div div{
      background: url('../assets/images/admin/images.png') 0 0 no-repeat !important;

  }
}

.everythingTab div{
  &:hover > div {
        background: url('../assets/images/admin/cloud.png') 0 -20px no-repeat;
      }
    &:active > div {
        background: url('../assets/images/admin/cloud.png') 0 0 no-repeat;
    }
  div{
    width: 106px;
    background: url('../assets/images/admin/cloud.png') 0 -40px no-repeat;
    top: 14px;
    left: 37px;
   }
}
.videosTab div{
  &:hover > div {
        background: url('../assets/images/admin/videos.png') 0 -20px no-repeat;
      }
    &:active > div {
        background: url('../assets/images/admin/videos.png') 0 0 no-repeat;
    }
  div{
    width: 80px;
    background: url('../assets/images/admin/videos.png') 0 -40px no-repeat;
    top: 14px;
    left: 54px;
  }
}
.selectedTabVideos{
  div div{
      background: url('../assets/images/admin/videos.png') 0 0 no-repeat !important;

  }
}
.appsTab div{
  &:hover > div {
        background: url('../assets/images/admin/apps.png') 0 -20px no-repeat;
      }
    &:active > div {
        background: url('../assets/images/admin/apps.png') 0 0 no-repeat;
    }
  div{
    width: 117px;
    background: url('../assets/images/admin/apps.png') 0 -40px no-repeat;
      top: 14px;
    left: 28px;
  }
}
.selectedTabApps{
  div div{
      background: url('../assets/images/admin/apps.png') 0 0 no-repeat !important;

  }
}

.carouselTab div{
  &:hover > div {
        background: url('../assets/images/admin/packs.png') 0 -20px no-repeat;
    }
    &:active > div {
        background: url('../assets/images/admin/packs.png') 0 0 no-repeat;
    }
  div{
  width: 162px;
    background: url('../assets/images/admin/packs.png') 0 -40px no-repeat;
    top: 14px;
    left: 15px;
    }
}
.selectedTabCarousel{
  div div{
      background: url('../assets/images/admin/packs.png') 0 0 no-repeat !important;

  }
}
.mainContainer{
    padding: 0;
}
#containerItemsMenu{
    .open p {
        border-top: 1px solid rgba(0,0,0,.15);
        border-left: 1px solid rgba(0,0,0,.15);
        border-right: 1px solid rgba(0,0,0,.15);
        border-bottom: 1px solid #fff;
    }
}
.subMenuItems{
    display: inline-block;
    padding-left: 10px;
    text-align: center;
    cursor:pointer;
    color: #428bca;

    .retail-name {
      font-size: 18px;
      line-height: 40px;
    }
}
#tileContainer {
    margin-top: 70px;
}
.searchType {
    height: 150px;
}
#searchForm {
    width: 500px;
    margin: 0 auto;
}
#searchForm ul li {
    display: inline;
    list-style: none;
}
.row-centered {
    text-align: center;
}
.col-centered {
    display: inline-block;
    float: none;
    /* reset the text-align */

    text-align: left;
    /* inline-block space fix */

    margin-right: -4px;
}
.col-fixed {
    /* custom width */

    width: 320px;
}
.selectedTile {
    background: black !important;
}
#mainContainerItems{
    display: block;
    position: relative;
    width: 100%;
    padding: 0;
    margin-top: 149px;
}
.menuFilter{
  padding: 0!important;
}

/******************CSS FOR FINAL VIEW LOGIN*************************/

.clearLogo{
  clear: left;
}
#loginDiv img {
    height: 400px;
    margin-left: 90px;
    margin-top: 15px;
  z-index: -100;
}

.loginBoxes {
    img {
        float: left;
    }
    p {
        float: left;
        font-family: @secondaryFont100;
        font-size: 74px;
        margin-top: 22px;
    }
    div{
        color: #FFFFFF;
        width: 100%;
    }
    .videos {
        background: #0565c2;
        float: left;
    }
    .applications {
        background: #f1ae00;
        float: left;
    }
    .packages {
        background: #6d2485;
        float: left;
    }
    .images {
        background: #b7295a;
        float: left;
    }
}

.formLogin {
    width: 207px;
    ::-webkit-input-placeholder{ /* WebKit, Blink, Edge */
        color: #CCCCCC;
        font-family: @secondaryFont500;
        font-size: 14px;
    }
    :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
        color: #CCCCCC;
        font-family: @secondaryFont500;
        font-size: 14px;
    }
    ::-moz-placeholder { /* Mozilla Firefox 19+ */
        color: #CCCCCC;
        font-family: @secondaryFont500;
        font-size: 14px;
    }
    :-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: #CCCCCC;
        font-family: @secondaryFont500;
        font-size: 14px;
    }
    :placeholder-shown { /* Standard (https://drafts.csswg.org/selectors-4/#placeholder) */
        color: #CCCCCC;
        font-family: @secondaryFont500;
        font-size: 14px;
    }
    ul li input {
        width: 100%;
        padding-left: 10px;
        border: 1px solid #5A5A5A;
        font-family: @secondaryFont500;
    }
    ul li {
        list-style: none;
    }
    ul li a {
        color: #0685c2;
        font-family: @secondaryFont700;
        float: right;
        font-size: 12px;
        margin-bottom: 5px;
        text-decoration: none;
    }

    input {
      &::placeholder {
        color: #5A5A5A;
      }
    }
}

.treeview ul li {
    padding: 10px 0;
    border-bottom: solid 1px;
}
.treeview ul ul li:last-child {
    border-bottom: none;
}
#searchInput {
    width: 400px;
    float: right;
    margin-top: 35px;
}
.pagination {
    width: 300px;
    line-height: 35px;
  float: right;
  margin-right: 49px !important;
    margin-top: 16px !important;
    margin-bottom: 8px !important;
  margin-left: 35px;
    select {
        box-sizing: border-box;
        width: 58px;
        height: 30px;
        padding-left: 9px;
        margin: 0 10px;
    }
    p,
    select {
        font-family: @secondaryFont500;
        float: left;
        font-size: 13px;
    }
    select {
        margin-top: 5px;
        outline: none;
        &:hover {
            cursor: pointer;
        }
    }
    p:first-child {} button {
        float: left;
        margin-top: 5px;
        width: 16px;
        height: 30px;
        border: none;
        outline: none;
        background-size: 16px 30px;
    }
    button:first-child {
        background: url('../assets/images/admin/left_pagination.png') 0 0 no-repeat;
    background-size: 16px 120px;
        &:hover {
            cursor: pointer;
            background: url('../assets/images/admin/left_pagination.png') 0 -30px no-repeat;
      background-size: 16px 120px;
        }
        &:active {
            cursor: pointer;
            background: url('../assets/images/admin/left_pagination.png') 0 -60px no-repeat;
      background-size: 16px 120px;
        }
        &:disabled {
            cursor: pointer;
            background: url('../assets/images/admin/left_pagination.png') 0 100% no-repeat;
      background-size: 16px 120px;
        }
    }
    button:last-child {
        background: url('../assets/images/admin/right_pagination.png') 0 0 no-repeat;
      background-size: 16px 120px;
        margin-left: 10px;
        &:hover {
            cursor: pointer;
            background: url('../assets/images/admin/right_pagination.png') 0 -30px no-repeat;
      background-size: 16px 120px
        }
        &:active {
            cursor: pointer;
            background: url('../assets/images/admin/right_pagination.png') 0 -60px no-repeat;
      background-size: 16px 120px
        }
        &:disabled {
            cursor: pointer;
            background: url('../assets/images/admin/right_pagination.png') 0 -92px no-repeat;
      background-size: 16px 120px
        }
    }
    span {
        font-size: 40px;
        font-weight: lighter;
    }
}
#addFilter {
    margin-top: 10px;
    border: #444444 dashed;
}
#addFilter p {
    margin: 10px;
}
#addFilter:hover {
    cursor: pointer;
}
.filterTagsColumns {
    float: right;
}
.smallFilterTagColumn {
    width: 15%;
}
.bigFilterTagColumn {
    width: 60%;
}
.listHeaders {
    border-bottom: solid #0685c3 1px;
    position: absolute;
    line-height: 50px;
    top: 2px;
    left: 15px;
    width: 96%;
    background: white;
    z-index: 10;
    p {
        font-size: 20px;
        font-family: @secondaryFont300;
        color: #0685c2;
    }
    div{padding-left: 0;}
}
.listWrapper {
    position: relative;
}
.filterTagColumnAction {
    margin: 2px 5px;
}
.app-modal-window-addFilterTag .modal-dialog {
    width: 460px;
}
.formAddFilterTag {
    ::-webkit-input-placeholder {
        color: #CCCCCC;
        font-family: @secondaryFont700;
        font-size: 14px;
    }
    /*LOGIN PLACE HOLDER CSS MOZILLA*/

    ::-moz-placeholder {
        color: #CCCCCC;
        font-family: @secondaryFont700;
        font-size: 14px;
    }
      ::-ms-input-placeholder {
        color: blue;
        font-family: @secondaryFont700;
        font-size: 14px;
    }

    ul li {
        width: 100%;
        list-style: none;
    }
    ul li a {
        color: #0685c2;
        font-family: @secondaryFont700;
        float: right;
        font-size: 12px;
        margin-bottom: 5px;
        text-decoration: none;
    }
    input {
        width: 200px;
        float: left;
        height: 30px;
        margin-top: 5px;
    }
    .button1 {
        margin-top: 5px;
        width: 200px;
        float: left;
        margin-left: 10px
    }
}

.closeFolder {
    float: left;
    margin-right: 15px;
}

.closeFolder:hover {
    cursor: pointer;
}

.closeModal {
    float: right;
    margin-top: -10px;
    margin-right: -10px;
}
.closeModal:hover {
    cursor: pointer;
}
.headerAdmin div {
    height: 60px;
    color: white;
    line-height: 60px;
    background: #6D6D6D;
}
.headerAdmin p {
    margin-left: 10px;
}
.headerAdmin div:hover {
    cursor: pointer
}
#footerID {} #footerID .container {
    height: 40px;
    background: #6D6D6D;
    color: white;
}
#footerID div:first-child {
    float: left;
    margin-top: 15px;
}
#footerID div:last-child {
    float: right;
    margin-top: 10px;
}
#logout {
    float: right;
    color: #0685c2;
    font-family: @secondaryFont500;
    margin-bottom: 5px;
    &:hover {
        cursor: pointer;
    }
    p {
        margin-top: 10px;
    }
    img {
        margin-top: -5px;
    }
}
#logout-menu {
    float: right;
    color: #0685c2;
    background-color: #fff;
    font-family: @secondaryFont500;
    margin-bottom: 5px;
    border: 1px solid rgba(0,0,0,.15);
    min-width: 100px;
    width: 102px;
    left: -64px;
    position: absolute;
    top: 49px;
    z-index: 100;
    a {
        font-size: 14px;
        margin: 10px 10px 7px 12px;
        padding: 0;
        color: #428bca;
        background-color: #fff;
    }
}

#cog-menu {
    float: right;
    max-width: 47px;
    max-height: 69px;
    position: relative;
    &:hover {
        cursor: pointer;
    }
    p {
        font-size: 14px;
        margin-top: 20px;
        padding: 4px 10px;
        z-index: 101;
    }
    img {
        margin-top: -5px;
    }

    &.btn-users {
      p {
        margin-top: 0;
      }
    }
}
.list {
    margin-top: 20px;
    li {
        list-style: none;
        padding: 15px;
        border-bottom: solid 1px #CCCCCC;
        font-family: @secondaryFont500;
        font-size: 14px;
        color: #454545;
    }
}
.manageUsersActionButtons div {
    width: 50%;
    float: right;
    color: #0685c2;
    font-family: @secondaryFont700;
    font-size: 14px;
    text-align: right;
    span {
        margin-top: 5px;
        margin-left: 20px;
    }
    img {
        margin-top: -10px;
    }
  :hover{
    text-decoration: underline;
    cursor: pointer;
  }
}
.button1{

    border: none;
    color: #fff;
    height: 30px;
    padding-left: 4px;
    padding-right: 4px;
    span{
     margin-left:5px;
    }
    &:hover{
      background: #79b843;
    }
    &:active{
      background: #4d7029;
    }
    &:focus{
    background: #669933;
    }
  &:disabled{
    background: #669933;
  }
}
#addUserModalButton{
    float: right;
    clear: both;
}
#addUser {
    float: right;
    clear: right;
    margin-top: 17px;
    margin-right: 65px;
    i {
        margin-right: 5px;
    }
}

.formAddFolder {
    input:-webkit-input-placeholder {
         color: #CCCCCC;
     }
     /*LOGIN PLACE HOLDER CSS MOZILLA*/

     input:-moz-placeholder {
         color: #CCCCCC;
     }
       input:-ms-input-placeholder {
         color: #CCCCCC;
     }

     ul:first-child{
         clear: both;
         padding-left: 0 !important;
         padding-right: 0 !important;
     }
     ul li {
     position: relative;
         list-style: none;
         width: 100%;
       }

     input {
         border: 1px solid #ccc;
         color: #5e5e5e;
         font-size: 14px;
         height: 24px;
         width: 100%;
         font-family: @secondaryFont500;
     }

     .button1 {
        margin-top: 10px;
     }
 }

.formAddUser {
   input:-webkit-input-placeholder {
        color: #CCCCCC;
    }
    /*LOGIN PLACE HOLDER CSS MOZILLA*/

    input:-moz-placeholder {
        color: #CCCCCC;
    }
      input:-ms-input-placeholder {
        color: #CCCCCC;
    }
      margin-top: 25px;
    ul {
        float: left;
    }
    ul:first-child{
        clear: both;
    }
    ul li {
    position: relative;
        list-style: none;
        margin: 15px;
        width: 100%;
      margin-left: -14px;
      }
    select {
        border: solid 1px #CCCCCC;
        width: 100%;
    height: 24px;
    -moz-appearance:none;
    -webkit-appearance:none;
      background: url('../assets/images/admin/select_down.png') no-repeat right;
      padding-left: 8px;
    }
    select::-ms-expand{
      display: none;
    }
    select option:first-child{
    color: #CCCCCC;
  }
  select:disabled{
    color: #CCCCCC;
  }
  select option{
    color: black;
  }
    div {
        width: 250px;
        margin: auto;
    float: right;
      margin-right: 29px;
    }
    button {
        margin-top: 10px;
    }
    input {
        border: 1px solid #ccc;
        color: #5e5e5e;
        font-size: 14px;
        height: 24px;
        padding-left: 10px;
        width: 100%;
        font-family: @secondaryFont500;
    }
}
.empty{
  color: #CCCCCC;
}
#addUserTitle{
  margin: 0;
  margin-bottom: 20px;
}
#addUserWrap , #addContentWrap  {
  #firstChild{
    margin-right: 10px;
  }
    span {
        float: right;
    }
    span:hover {
        cursor: pointer;
    }
  }
#addUserWrap {
  height: 380px;
  }

  #addfolderWrap  {
  #firstChild{
    margin-right: 10px;
  }
    span {
        float: right;
    }
    span:hover {
        cursor: pointer;
    }
  }
#addfolderWrap {
  height: 175px;
  }

#deleteUserWrap {
    span {
        float: right;
    }
    span:hover {
        cursor: pointer;
    }
    height: 224px;
    width: 500px;
  }
.pageheader {
    margin-top: -77px;
}
.titleContainer {
    float: left;
    width: 250px;
    clear: both;
    margin: 0;
    margin-left: -7px;;
    img {
        margin: 0 20px 8px 0;
    }
}
.listContainer {
    border: solid 2px #CCCCCC;
    padding: 30px 0;
  height:80vh;
    min-height: 421px;
  overflow-y: scroll;
  overflow-x: hidden;
  span3{
    input{
      width: 100%;
      height: 30px;
      padding-left: 10px
    }
    select{
      width: 220px;
      height: 30px;
    }
  }
}
.listContainer::-webkit-scrollbar-button:start{
    background: url('../assets/images/admin/scroll_up.png') 0 0;
}
.listContainer::-webkit-scrollbar-button:start:hover {
  cursor: pointer;
    background: url('../assets/images/admin/scroll_up.png') 0 -15px;
}
.listContainer::-webkit-scrollbar-button:start:active  {
    background: url('../assets/images/admin/scroll_up.png') 0 -30px;
}
.listContainer::-webkit-scrollbar-button:end {
    background: url('../assets/images/admin/scroll_down.png') 0 0;
}
.listContainer::-webkit-scrollbar-button:end:hover {
  cursor: pointer;
    background: url('../assets/images/admin/scroll_down.png') 0 -15px;
}
.listContainer::-webkit-scrollbar-button:end:active  {
    background: url('../assets/images/admin/scroll_down.png') 0 -30px;
}
.listContainer::-webkit-scrollbar {
    width: 14px;
}
.listContainer::-webkit-scrollbar-thumb {
    background: #CCCCCC;
}
.listContainer::-webkit-scrollbar-track-piece {
    background: white;
}
.listContainer::-ms-scrollbar-button:start {
    background: url('../assets/images/admin/scroll_up.png') 0 0;
}
.listContainer::-moz-scrollbar-button:start:hover {
  cursor: pointer;
    background: url('../assets/images/admin/scroll_up.png') 0 -15px;
}
.listContainer::-moz-scrollbar-button:start:active  {
    background: url('../assets/images/admin/scroll_up.png') 0 -30px;
}
.listContainer::-moz-scrollbar-button:end {
    background: url('../assets/images/admin/scroll_down.png') 0 0;
}
.listContainer::-moz-scrollbar-button:end:hover {
  cursor: pointer;
    background: url('../assets/images/admin/scroll_down.png') 0 -15px;
}
.listContainer::-moz-scrollbar-button:end:active  {
    background: url('../assets/images/admin/scroll_down.png') 0 -30px;
}
.listContainer{
scrollbar-base: url('../assets/images/admin/scroll_down.png') 0 -30px;
}
.itemContainer::-webkit-scrollbar-button:start{
    background: url('../assets/images/admin/scroll_up.png') 0 0;
}
.itemContainer::-webkit-scrollbar-button:start:hover {
  cursor: pointer;
    background: url('../assets/images/admin/scroll_up.png') 0 -15px;
}
.itemContainer::-webkit-scrollbar-button:start:active  {
    background: url('../assets/images/admin/scroll_up.png') 0 -30px;
}
.itemContainer::-webkit-scrollbar-button:end {
    background: url('../assets/images/admin/scroll_down.png') 0 0;
}
.itemContainer::-webkit-scrollbar-button:end:hover {
  cursor: pointer;
    background: url('../assets/images/admin/scroll_down.png') 0 -15px;
}
.itemContainer::-webkit-scrollbar-button:end:active  {
    background: url('../assets/images/admin/scroll_down.png') 0 -30px;
}
.itemContainer::-webkit-scrollbar {
    width: 14px;
}
.itemContainer::-webkit-scrollbar-thumb {
    background: #CCCCCC;
}
.itemContainer::-webkit-scrollbar-track-piece {
    background: white;
}
.itemContainer::-ms-scrollbar-button:start {
    background: url('../assets/images/admin/scroll_up.png') 0 0;
}
.itemContainer::-moz-scrollbar-button:start:hover {
  cursor: pointer;
    background: url('../assets/images/admin/scroll_up.png') 0 -15px;
}
.itemContainer::-moz-scrollbar-button:start:active  {
    background: url('../assets/images/admin/scroll_up.png') 0 -30px;
}
.itemContainer::-moz-scrollbar-button:end {
    background: url('../assets/images/admin/scroll_down.png') 0 0;
}
.itemContainer::-moz-scrollbar-button:end:hover {
  cursor: pointer;
    background: url('../assets/images/admin/scroll_down.png') 0 -15px;
}
.itemContainer::-moz-scrollbar-button:end:active  {
    background: url('../assets/images/admin/scroll_down.png') 0 -30px;
}
.itemContainer{
scrollbar-base: url('../assets/images/admin/scroll_down.png') 0 -30px;
}

.success div{
  width: 240px;
  margin: auto;
  clear: both;
  text-align: center;
  line-height: 5;
  img{
    margin-top: 40px;
  }
}
#okButton{
  float:right;
  margin-top: 25px;
  margin-right: 18px;
  padding: 4px 17px;
 }

#cancelButton{
  float:right;
  margin-top: 15px!important;
  margin-right: 18px;
  background-color: #428bca;
}

#logoutButton{
  float:right;
  margin-top: 15px!important;
  margin-right: 11px;
  background-color: #cc0000;
}

.addCollection {
  width: 30px;
  height: 30px;
  margin: 12px 0 12px 12px;
    float: right;
    cursor: pointer;
    background: url('../assets/images/library/add_collection.png') center center no-repeat;
    transition: background-image 400ms ease;

    &:hover {
        background-image: url('../assets/images/library/add_collection_hover.png');
    }

    &.active {
        background-image: url('../assets/images/library/added_collection.png');

        &:hover {
            background-image: url('../assets/images/library/added_collection_hover.png');
        }
    }
}


.addFolder {
    width: 20px;
    height: 28px;
    margin-left: 8px;
    margin-right: 8px;
    margin-top: 5px;
    float: left;
    cursor: pointer;
    background: url('../assets/images/library/add-green.png') center center no-repeat;
    transition: background-image 400ms ease;
  }

.deleteContent{
  width: 28px;
  height: 28px;
  margin: 12px;
    float: left;
    background: url('../assets/images/library/delete_asset.png') 0 0 ;
  background-size:100%;

}
.deleteContent:hover {
  cursor: pointer;
    background: url('../assets/images/library/delete_asset_hover.png') 0 0 ;
  background-size:100%;
}
.deleteContent:active  {
    background: url('../assets/images/admin/delete_asset_sprite.png') 0 0 ;
  background-size:100%;
}
.downloadCollection{
  width: 38px;
  height: 37px;
  margin: 4px;
  position: absolute;
  left:0;
    z-index: 1;
    background: url('../assets/images/admin/download_file.png') 0 0 ;
  background-size:100%;
  a{
    display: block;
      height: 100%;
  }
}
.downloadCollection:hover {
  cursor: pointer;
    background: url('../assets/images/admin/download_file.png') 0 -37px ;
  background-size:100%;
}
.downloadCollection:active  {
    background: url('../assets/images/admin/download_file.png') 0 -74px ;
  background-size:100%;
}
.deleteCollection{
  width: 28px;
  height: 28px;
    z-index: 1;
    margin: 12px;
  position: absolute;
  left:0;
    background: url('../assets/images/admin/delete_file.png') 0 0 ;
  background-size:100%;
}
.deleteCollection:hover {
  cursor: pointer;
    background: url('../assets/images/admin/delete_file_hover.png') 0 0 ;
  background-size:100%;
}
.deleteCollection:active  {
    background: url('../assets/images/admin/delete_file_hover.png') 0 0 ;
  background-size:100%;
}
.menuHeader{
    width: 100%;
}

.subMenu{
    width: 100%;
    margin-bottom: 30px;
}

#logo {
    display: inline-block;

    img {
      float: left;
      width: 100px;
      margin: 10px;
    }

    p {
        color: #0685c2;
        font-family: @secondaryFont500;
        font-size: 13px;
        display: inline-block;
        margin-top: 30px;
    }
}

.itemContainer{
    border-top: none;
    float: right;
    min-height: 522px;
    width: 100%;
    padding: 0;
}
#everything{
    background: url('../assets/images/admin/cloud.png') 0 10px no-repeat;
  width: 13px;
  height: 10px;
}
.selectedTabEverything{
  div div{
      background: url('../assets/images/admin/cloud.png') 0 0 no-repeat !important;

  }
}

.selectedTab{
  border: solid 2px #CCCCCC;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border-bottom: none;

  div{
    background-color: white;
    border:solid 2px #CCCCCC;
    border-bottom: none;
  }
  div p{
    color:#0685c2;
  }
  :hover > div p{
    color: white;
  }
  :hover{
    margin-left:0;
  }
}
#filtersHeader{
    color: #0685c2;
    background: #dadada;
    padding: 5px 0 5px 0;
    font-size: 14px;
    font-family: @secondaryFont500;
    height: 30px;
    padding-left: 20px;
  div{
    line-height: 1;
    height: 100%;
      background: #0685c2;
    color: white;
    float: left;
  }
  div:hover{
    cursor: pointer;
  }
  div p{
    margin-top: 18px;
  }
  div:last-child p{
  }
  .notSelectedFilter{
      background: white;
    color: #0685c2;
    float: left;
  }
    #headerTittleFilter{
        color: #0685c2;
        background: #dadada;
        padding: 5px 0 5px 0;
    }
}
@media (max-width: 1200px) {
.menuFilter{
  width: 1210px;
}
}
#specialWidth{
    float:left;
    width:100%;
  .refine-title {
    p{
      padding-left: 5px;
      color: #0685c2;
      font-size: 14px;
      font-family: @secondaryFont500;
      padding-top: 10px;
    }
  }
}

.sort-section{
  height: 22px;
  padding-top: 10px;
  padding-left:10px;
}
#filterDetail{
    background: #dadada;
    font-family: @secondaryFont500;
    margin-bottom: 60px;
  .filter{
    height: 45px;
      border-bottom: 2px #CCCCCC solid;
    }
  .filter p{
        font-family: @secondaryFont300;
        font-size: 17px;
      width: 100%;
      float: left;
      margin-top: 15px;
      margin-left: 18px;
  }

  .back-to-main-filter {
    background: #fff;

    a {
      background: #fff;
      text-decoration: none;
      cursor: pointer;
      margin-left: 5px;
    }

    .lineHeaderTittle {
      height: 0.5em;
    }
  }

  .search-fga-model {
    float: none;
    padding: 0;

    .lineHeaderTittle {
      height: 0.5em;
    }

    form {
      div {
        border: 2px solid #CCCCCC;
        background-color: #fff;

        input[name="searchFgaOrModelBar"] {
          width: 100%;
          border: 0;
          padding: 5px 7px;
        }
      }
    }
  }

  .accordionTitleHead {
    padding: 5px 0 3px 0;

    .accordionTitle {
      font-family: @secondaryFont500;
      font-size: 16px;
      color: #fff;
      text-transform: uppercase;
      width: 100%;
      display: block;

      span {
        margin-left: 20px;

        .glyphicon {
          font-size: 19px;
          font-weight: 900;
          float: right;
          margin-right: 9px;
          margin-top: -2px;
          display: none;
        }
      }
    }

    a {
      &:hover, &:focus {
        text-decoration: none;
      }
    }
  }
  p img{
      float: right;
      margin-top: 8px;
  }
  span{
  }

}
#addContentImg{
    float: left !important;
    margin-top: 4px !important;
}

.tabContainer{
    padding-left: 0;
}
.selectedFilter{
  color: #0685c2;
}
.footerRepository{
  margin-left: 20px;
  line-height:40px;
  margin-top: 0;
  position: inherit;
}
#deleteUserAnswerContainer{
  clear: both;
  float: right;
  margin-right: 16px;
  button{
    margin: 45px 10px;
    height: 30px;
    font-family: @secondaryFont500;
    font-size: 15px;
  }
  button:first-child{
    background: #0685C2;
  }
  button:last-child{
    background: #CC0000;
  }
}
#updateUserAnswerContainer{
  float: right;
  button{
    margin: 0 10px;
    height: 30px;
    font-family: @secondaryFont500;
    font-size: 15px;
  }
  div button:first-child{
    margin-right: 0;
  }
  div button:last-child {
    background: #CC0000;
  }
  div button{
    float: right;
    margin: 0 10px 0 10px;
  }
}
.subtitle{
  float: left;
    font-family: @secondaryFont500;
  font-size: 15px;
  color: #454545;
  margin-top: 6px;
}
div[contentEditable] {
  cursor: pointer;
  background-color: white;
    padding: .2em;
}
div[contentEditable]:focus {
  cursor: pointer;
    border: 1px solid #454545;
  padding: .2em;
}
.error{
  border: solid 1px #cc0000 !important;
}
.errorMessage{
  position:absolute;
    font-size: 12px !important;
    font-family: @secondaryFont500;
    margin: 0;
    color: #cc0000 !important;
}
.successInput{
  border: solid 2px #79b843  !important;
}
.successMessage{
  position:absolute;
    font-size: 12px !important;
    font-family: @secondaryFont500;
    margin: 0;
    color: #79b843 !important;
}

.sorting{
    p{
     color: #0685c2;
    }
    img{
    cursor: pointer;
    }
}

.accordionDetail{
  border-bottom: 1px solid #efeeee;
  label{
        font-size: 15px;
        font-family: @secondaryFont500;
        color: #284a7e;
        font-weight: 100;
        width: 100%;
        margin-bottom: 5px;
        margin-top: 5px;
  }
  input[type="checkbox"] {
      display:none;
  }
  input[type="checkbox"] + label span {
      display:inline-block;
      width:19px;
      height:19px;
      margin:0 12px 0 16PX;
      vertical-align:middle;
      background: url('../assets/images/library/checkbox.png') 0 0 no-repeat;
      cursor:pointer;
  }
  input[type="checkbox"]:checked + label span {
      background: url('../assets/images/library/checkbox.png') 0 -22px no-repeat;
  }
  input[type="checkbox"] + label:hover {
    cursor: pointer;
  }
}



.campaignFilter{
  background-color:@colorCampaign;
  label{
    color:  #fff;
  }
  input[type="checkbox"] + label span {
    background: url('../assets/images/library/checkbox_light.png') 0 0 no-repeat;
  }
  input[type="checkbox"]:checked + label span {
    background: url('../assets/images/library/checkbox_light.png') 0 -22px no-repeat;
  }
}

.toolkitFilter{
  background-color:@colorToolkit;
  label{
    color:  #fff;
  }
  input[type="checkbox"] + label span {
    background: url('../assets/images/library/checkbox_light.png') 0 0 no-repeat;
  }
  input[type="checkbox"]:checked + label span {
    background: url('../assets/images/library/checkbox_light.png') 0 -22px no-repeat;
  }
}

.imageFilter{
  background-color:@colorImage;
  label{
    color:  #fff;
  }
  input[type="checkbox"] + label span {
    background: url('../assets/images/library/checkbox_light.png') 0 0 no-repeat;
  }
  input[type="checkbox"]:checked + label span {
    background: url('../assets/images/library/checkbox_light.png') 0 -22px no-repeat;
  }
}

.rplgFilter{
  background-color:@colorRplg;
  label{
    color:  #fff;
  }
}

.rcagFilter{
  background-color:@colorRcag;
  label{
    color:  #fff;
  }
  input[type="checkbox"] + label span {
    background: url('../assets/images/library/checkbox_light.png') 0 0 no-repeat;
  }
  input[type="checkbox"]:checked + label span {
    background: url('../assets/images/library/checkbox_light.png') 0 -22px no-repeat;
  }
}

.digitalActivationFilter{
  background-color:#6e2585;
  label {
    color: #fff;
  }
}

.videoFilter{
  background-color:@colorVideo;
  label {
    color: #fff;
  }
  input[type="checkbox"] + label span {
    background: url('../assets/images/library/checkbox_light.png') 0 0 no-repeat;
  }
  input[type="checkbox"]:checked + label span {
    background: url('../assets/images/library/checkbox_light.png') 0 -22px no-repeat;
  }
}

.guidelinesFilter{
  background-color:@colorGuides;
  label{
    color:  #fff;
  }
  input[type="checkbox"] + label span {
    background: url('../assets/images/library/checkbox_light.png') 0 0 no-repeat;
  }
  input[type="checkbox"]:checked + label span {
    background: url('../assets/images/library/checkbox_light.png') 0 -22px no-repeat;
  }
}

.templateFilter{
  background-color:@colorTemplates;
  label{
    color:  #fff;
  }
  input[type="checkbox"] + label span {
    background: url('../assets/images/library/checkbox_light.png') 0 0 no-repeat;
  }
  input[type="checkbox"]:checked + label span {
    background: url('../assets/images/library/checkbox_light.png') 0 -22px no-repeat;
  }
}

.subFilter{
  border-bottom: 1px solid #dfdede !important;
  label{  
      padding-left: 15px;
      color: #515151 !important;
  }
  input[type="checkbox"]:checked + label span {
    background: url('../assets/images/library/checkbox.png') 0 -22px no-repeat;
  }
  input[type="checkbox"] + label span {
    background: url('../assets/images/library/checkbox.png') 0 0 no-repeat;
  }
  input[type="checkbox"] + label:hover {
    cursor: pointer;
  }
}

#clearAll{
    float: left;
    font-size: 10px;
    border-top: 1px solid #efeeee;
    color: #0685c2;
    margin: 0;
    background: #dadada;
    left: 0;
    position: relative;
    padding: 7px 0 7px 0;
    border: none;
    height: 33px;
    width: 100%;
    text-align: center;
    margin-top: 1px;
}
#clearAll p{
    font-size: 12px;
    font-weight: 600;
}
#clearAll p:hover{
    cursor:pointer
}

.choosethumbnail {
    background: white;
    width: 868px;

    .choosethumbnailBoxes {

        span {
            float: none !important;
        }
        label {
            margin-top: 5px;
            font-size: 15px;
        }
        label:nth-of-type(1) {
            width: 210px;
        }
        label:nth-of-type(2) {
            width: 180px;
        }
        p {
            bottom: 68px;
        }

        #realThumbnailInput {
            opacity: 0;
            position: absolute;
            z-index: 1;
            width: 50px;
        }
        #realThumbnailInput:hover {
            cursor: pointer;
        }

        #fakeThumbnailInput {

            input[type="text"] {
                display: none;
                position: absolute;
                top: 0;
                left: 0;
            }
        }
    }

    .choosethumbnailBoxes > div {
        float: right;
        margin-right: 310px;
        margin-top: 4px;
        position: relative;
    }
}

.choosethumbnailBoxes {
    margin-top: 3px;
    label {
        font-size: 14px;
        font-family: @secondaryFont500;
        color: #454545;
        font-weight: 100;
        width: 28%;
    }
    input[type="checkbox"] {
        display: none;
    }
    input[type="checkbox"] + label span {
        display: inline-block;
        width: 19px;
        height: 19px;
        margin: -1px 4px 0 0;
        vertical-align: middle;
        background: url('../assets/images/library/checkbox.png') 0 0 no-repeat;
        cursor: pointer;
        margin-right: 16px;
        margin-top: -5px;
    }
    input[type="checkbox"]:checked + label span {
        background: url('../assets/images/library/checkbox.png') 0 -22px no-repeat;
    }
    input[type="checkbox"] + label:hover {
        cursor: pointer;
    }
}

.app-modal-window-assetShareUrl .modal-body-share{
  width: 870px;
  height: 371px;
}
.left{float: left;}
.right{float: right;}

#addContentTitle{
  margin:0 0 25px 0;
}

.subjectInput{
    width: 770px;
  }

.dellEmailTextArea{
    border: 2px rgb(204,204,204) solid;
    height: 200px !important;
    border-radius: 0;
    resize: none;
  }

.dellTextArea{
  border: 2px rgb(204,204,204) solid;
  height: 80px !important;
  border-radius: 0;
  resize: none;
}
.biggerModal{
  height: 530px !important;
}
.button2{
  height: 30px;
    margin-left: 14px;
    margin-top: 28px;
}
.dateContainer{
  font-size: 13px;
    font-family: @secondaryFont500;
  margin-bottom: 20px;
  float: left;
    width: 100%;
  position: relative;
  select{
    width: 90px;
    height: 30px;
    float: left;
    margin: 0;
    padding-left: 5px;
  }
  span2{
    float: left;
    margin: 0 7px;
    line-height: 30px;
  }
  input[type="checkbox"] {
      display:none;
  }
  input[type="checkbox"] + label {
    margin-left: 10px;
    line-height: 30px;
    font-weight: 100;
  }
  input[type="checkbox"] + label span3 {
      display:inline-block;
      width:19px;
      height:19px;
      margin:-1px 4px 0 0;
      vertical-align:middle;
      background: url('../assets/images/library/checkbox.png') 0 0 no-repeat;
      cursor:pointer;
    margin-top: -5px;
  }
  input[type="checkbox"]:checked + label span3 {
      background: url('../assets/images/library/checkbox.png') 0 -20px no-repeat;
  }
  input[type="checkbox"] + label:hover {
    cursor: pointer;
  }
  .errorMessage{
    bottom: -25px;
  }
}
#filterSelectorInput, .filterSelectorInput{
  width:100%;
  border: solid 1px #CCCCCC;
  height: 30px;
    background: url('../assets/images/admin/select_down.png') no-repeat right;

  span{
    margin:3px 0 0 10px;
    float: left;
  }
  &:hover{
    cursor: pointer;
  }
}

.detailText{
    margin-top: 110px;
    color: #454545;
    font-family: @secondaryFont500;
    font-size: 10px;
}
#modalWindow{
  span{
    float: right;
  }
  span:hover{
    cursor: pointer;
  }
  .dellTitle{
    margin-top: 0;
  }
  .logoutTitle{
    margin-bottom: 10px;
  }
  .modalWindowTitle{
    width: auto;
  }
  .modalWindowMessage{
    float: left;
    margin-bottom: 50px;
    margin-left: -5px;
    width: 100%;
    button{
      margin-top: 40px;
    }
    #secondMessage{
      margin-top: 5px;
    }
  }
  .logoutMessage{
    margin-bottom: 24px;
    font-size: 15px;
  }
}

.logout-modal{
  padding: 25px 45px 0 45px;
}

#specialInputError{
  bottom: -15px;
}
#deleteContentWrap{
  margin-left: 20px;
  .titleContainer{
    margin-top:5px;
    width: auto;
  }
  #deleteUserAnswerContainer button {
      margin: 25px 10px;
  }
  #addUserTitle{
      margin-bottom: 15px;
      margin-left: 5px;
  }
}
#collectionPagination{
  // clear: left;
}
#collection{
  padding: 20px;
    overflow: hidden;

  #collectionButtonContainer{
    margin-bottom: 10px;
    margin-top: 19px;
    float: right;
    clear: right;
    margin-right: 47px;
    position: relative;
    #inputImport{
      opacity:0;
      position: absolute;
      left: 10px;
      top: 0;
      width: 148px;
      height: 27px;
    }
    #inputImport:hover{
      cursor: pointer;
    }
    .button1{
      margin-left: 10px;
    }
    img{float: left;
    margin-top: 2px;}
  }
  .dellTitle{
    margin: 25px 0 10px 0;
    text-align: left;
  }
  span{
    float:right;
  }
  span:hover{
    cursor: pointer;
  }
  .item{
    margin: 0 9px 20px 9px;
  }
  .item span3 img{
    z-index: 0;
  }
  .itemContainer{
      width: 940px;
    min-height: 450px;
    border: none;
    margin-right: 18px;
  }
  .titleContainer{
    clear: none;
    margin: 0;
  }
  .pagination{
    margin: 0;
    width: auto;
  }
  .pageHeader{
      padding: 0 0 0 25px;
      float: left;
      margin-bottom: 17px;
      width: 100%;
      .dellTitle{
        margin-top:0;
      }
    }
}
#buttonImport{
  background: #0685C2;
}
#buttonExport{
  background: #0685C2;
}
#buttonClear{
  background: #D2092B;
}
#buttonCollectionDelete{
    background: #D2092B;
}
#addContentButtonContainer{
  float: left;width: 100%;
  height: 40px;
  position: relative;
}
#custom-accord{
  p{
    margin-left:20px;
  }
  label{
    /*margin-left:20px;*/
  }
  .accordionHeader{

        background: rgb(77,77,79);
        color: #ffffff;

  }
  .panel-group{
    position: relative;
    z-index: 1;
  }
  
}


@media (min-width: 768px) and (max-width: 991px) {
  #searchBar{
    button{
      width:auto !important;
    }
  }
}

#searchBar{
    font-size:14px;
    display:inline-block;
    margin-top: 0;
    position:absolute;
    margin-bottom:10px;
  input{
    width:70%;
    float:left;
    height:40px
  }
  button{
    background:#0685C2;
    width:90px;
    float:left;
    height: 40px;
    border-bottom-left-radius:0;
    border-top-left-radius:0;
    margin-top:0
  }
  select{
    width:215px;
    float:left;
    height:40px;
    background:url(../assets/images/library/drop.png) no-repeat right;
  }
  option{
    width:215px;
    float:left;
    padding: 15px;
    height:40px
  }
}

#emailLink {
  width: 240px;
  margin: auto;
  background: #0685C2;
  border-radius: 5px;
  color: #FFFFFF;
  font-size: 14px;
  padding: 7px;
  a {
    text-decoration:none;
    color: #FFFFFF;
  }
  &:hover{
    cursor: pointer;
  }
}
.button3{
  color: #669933;
  background: none;
    img {
        margin-right: 5px;
    }

}
.repositoryButtons {
    float: right;
  margin-top: 24px;
  margin-right: 18px;
    &:hover{
    cursor: pointer;
    }
    &.myCollection{
        margin-right: 25px;
    }

    &.myCollection .counter{
        position: relative;

        i {
            position: absolute;
            top: -3px;
            right: 5px;
            background: #ea9a00;
            font-size: 10px;
            color: #fff;
            font-family: @secondaryFont300;
            font-style: normal;
            border-radius: 100%;
            padding: 1px 3.5px;
            line-height: 1;
        }
    }
}
.repositoryLogout{
  margin-top: 24px !important;
  margin-right: 13px;
}
#noItemsMessage{
  text-align: center;
    margin-top: 30px;
    font-family: @secondaryFont500;
}

.margin-left{
    margin-left: 10px;
}
.special-link, .special-link:visited, .special-link:hover, .special-link:active {color:inherit;}

.tooltip.bottom .tooltip-inner {
    background-color: #0685c2;
    padding: 10px;
    font-family: @secondaryFont500;
}

.tooltip.bottom .tooltip-arrow {
    border-bottom-color: #0685c2;
}

.tooltip.top .tooltip-inner {
    background-color: #0685c2;
    padding: 10px;
    font-family: @secondaryFont500;
}

.tooltip.top .tooltip-arrow {
    border-bottom-color: #0685c2;
}

.tooltip.left .tooltip-inner {
    background-color: #0685c2;
    padding: 10px;
    font-family: @secondaryFont500;
}

.tooltip.left .tooltip-arrow {
    border-left-color: #0685c2;
}
#container_buttons{
    position: absolute;
    top: 0;
    width: 100%;
    padding-right: 20px;
}
.dropdown-menu {
    position: relative;
    top: 100%;
    left: -5px;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 160px;
    padding: 0;
    margin: 0 0 5px;
    list-style: none;
    font-size: 12px;
    width: 100%;
    color: #515151;
    background: #f0f0f0;
    font-family: @secondaryFont500;
    border-radius: 0!important;
    box-shadow: none;
    border: none;
    width: 195px;
}
.hide_subfilter{
   display: none;
}

.show_subfilter{
    display:block;
    margin-left: 5px !important;
    min-width: 0;
    width: 100%;
}
.selected {
    display: block;
}
#assetShareContentWrap{
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
    padding: 35px;
    .errorMessageShare {
        font-size: 12px!important;
        color: #c00!important;
        padding: 0;
    }

    .titleContainer{
        color: #c92466;
        font-family: @secondaryFont500;
        font-size: 20px;
        clear: none;
        margin-left: 0 !important;
        float: left;
        width: 55px;
    }
    #assetUrlShare{
        color: #454545;
        font-size: 16px;
        font-family: @secondaryFont500;
        text-align: center;
        padding: 0;
        margin-top: 15px;

        #containerUrl{
            position: relative;
            resize: none;
            font-size: 12px;
            padding: 0;
            height: 28px;
            line-height: 27px;
            overflow: hidden;
            padding-left: 10px;
            padding-right: 10px;
            font-family: @secondaryFont500;
            width: 88%;
            float: left;
        }



        #copyButtonUrl{
            position: relative;
            background: #669933;
            border: none;
            height: 28px;
            color: #fff;
            font-size: 13px;
            border-radius: 0 4px 4px 0;
            font-family: @secondaryFont500;
            width: 12%;
            float: left;
        }
    }

}
.app-modal-window-assetShareUrl .modal-body-share{
    width: 600px;
    height: 271px;
}
#assetShareWrap {
    span {
        float: right;
       margin-top: -10px;
        margin-top: 0;
    }
    span:hover {
        cursor: pointer;
    }
    height: 230px;
    width: 600px;
}
#assetShareTitle{
    margin: 0;
    margin-bottom: 50px;
    font-size: 20px;
}
#textShareCopyUrl{
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    font-family: @secondaryFont500;
    color: #949494;
}
.lastFilter{
    border-bottom: none!important;
}
.copyShareContent{
    background: url(../assets/images/library/copyCheck.png) 17px 0 no-repeat!important;
    background-color: #693!important;
    z-index: 10;
    font-size: 0 !important;
}
.lineHeaderTittle{
    height: 2px;
    width: 100%;
    background: #ffffff;
    display: block;
}
.containerImgThumb{
    //width: 215px;
    height: 202px;
}
.img_container{
    float: left;
    padding: 0;
    width: 30px;
    margin-right: 12px;
}
#shareUrl{
    display: none;
}
.search-bar-cont{
    padding-left: 10px;
    padding-right: 10px;
    margin: 0 0 10px 0;
    height: 40px;
}
.dropbtn {
    background-color: #669933;
    color: white;
    padding: 10px 10px 10px 35px;
    font-size: 14px;
    border: none;
    cursor: pointer;
    width: 100%;
    text-align: left;
    float: left;
}
.dropdown {
    position: relative;
    display: inline-block;
    width: 165px;
}
.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    width: 195px;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    z-index: 999;
}
.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}
.dropdown-content a:hover {background-color: #f1f1f1}
.dropdown:hover .dropdown-content {
    display: block;
    top: 40px;
}
.dropdown:hover .plus-icon {
    display: none;
}
.dropdown:hover .minus-icon {
    display: inline-block;
}
.dropdown:hover .dropbtn {
    background-color: #8cbf31;
}
.dell-item{
  margin: 0;
  padding: 0 4px 20px;
  cursor: pointer;
}
.dell-item a{
    color:none
}
.dell-item a:hover{
    font-style:none
}
.dell-item .containerImgThumb{
    background:none center center no-repeat;
    background-size:cover;
    border-top: 1px solid #eeeeee;
    border-left: 1px solid #eeeeee;
    border-right: 1px solid #eeeeee;
}
.dell-item .info{
    position:relative;
    bottom:0;
    width:100%;
    height:78px;
    max-height:78px;
    min-height:78px;
    padding-left:0;
    padding-right:0
}
.dell-item .info p{
    color:#454545;
    float:left;
    font-size:12px;
    overflow:hidden;
    width:140px
}
.dell-item .info span{
    font-size:14px;
    position:absolute;
    bottom:5px;
    right:10px
}
.dell-item .info span2 img{
    width:20px;
    height:27px;
    margin:14px 10px 0 13px;
    float:left
}
.dell-item .info .description{
    margin-top:10px
}
.dell-item .info .row_1{
    padding-left:0;
    padding-right:0;
    height:43px;
    max-height:43px;
    min-height:43px;
}
.dell-item .info .row_1 .tooltip{
    width:100%;
    left:5px!important;
    word-wrap:break-word;
    top:33px!important
}
.dell-item .info .row_1 .tooltip .tooltip-inner{
    width:100%;
    background-color:#fff!important;
    color:#000;
    overflow:hidden
}
.dell-item .info .row_1 .tooltip .tooltip-arrow{
    border-bottom-color:#fff!important;
    margin-left:-50px!important
}
.dell-item .info .row_1 .tooltip .tooltip.bottom .tooltip-arrow{
    top:0;
    left:50%;
    margin-left:-5px;
    border-width:0 5px 5px;
    border-bottom-color:transparent!important;
    background-color:#fff!important;
    text-shadow:1px 1px 1px #000
}
.dell-item .info .row_1 .nameAsset{
    text-overflow: ellipsis;
    word-wrap:nowrap;
    width:153px!important;
    height:25px;
    font-size:14px;
    padding:0;
    margin-left:5px;
    float:left;
    margin-top:12px;
    overflow:hidden;
    white-space: nowrap;
    line-height:1
}
.dell-item .info .row_1 .nameAsset.long{
    margin-top:12px
}
.dell-item .info .row_2{
    padding-left:0;
    padding-right:0;
    height:34px;
    max-height:34px;
    min-height:34px;
    overflow:hidden;
    border-bottom:1px solid rgba(51,51,51,.13);
}
.dell-item .info .row_2 ul{
    position:relative;
    bottom:0;
    margin-top:7px
}
.dell-item .info .row_2 li{
    display:inline-block;
    width:17px;
    height:17px;
    margin-right:12px
}
.dell-item .info .row_2 .hideAssetCount{
    display:none!important
}
.dell-item .info .row_2 .showAssetCount{
    display:block!important
}
.dell-item .info .row_2 .text_counter_plus{
    position:relative;
    top:3px;
    font-size:9px;
    font-weight:500;
    border:none!important;
    text-align:center;
    letter-spacing:-.2px;
    transform:scaley(1.2);
    display:none
}
.dell-item .info .row_2 .download_asset{
    background:url(../assets/images/library/download_file.png) no-repeat
}
.dell-item .info .row_2 .download_asset_video{
    background:url(../assets/images/library/download_file_video.png) no-repeat
}

.dell-item .info .row_2 .download_asset_guides{
    background:url(../assets/images/library/download_file_guides.png) no-repeat
}

.dell-item .info .row_2 .download_asset_app{
    background:url(../assets/images/library/download_file_app.png) no-repeat
}

.dell-item .info .row_2 .download_asset_image{
    background:url(../assets/images/library/download_file_images.png) no-repeat
}

.dell-item .info .row_2 .download_asset_templates{
    background:url(../assets/images/library/download_file_templates.png) no-repeat
}

.dell-item .info .row_2 .download_asset a{
    width:16px;
    height:16px;
    position:absolute
}
.dell-item .info .row_2 .share_asset{
    background:url(../assets/images/library/sharing-interface.png) no-repeat
}

.dell-item .info .row_2 .share_asset_video{
    background:url(../assets/images/library/sharing-interface_video.png) no-repeat
}

.dell-item .info .row_2 .share_asset_guides{
    background:url(../assets/images/library/sharing-interface_guides.png) no-repeat
}

.dell-item .info .row_2 .share_asset_app{
    background:url(../assets/images/library/sharing-interface_app.png) no-repeat
}

.dell-item .info .row_2 .share_asset_image{
    background:url(../assets/images/library/sharing-interface_images.png) no-repeat
}

.dell-item .info .row_2 .edit_asset_image{
    background:url(../assets/images/library/edit_images.png) no-repeat
}

.dell-item .info .row_2 .share_asset_templates{
    background:url(../assets/images/library/sharing-interface_templates.png) no-repeat
}

.dell-item .info .row_2 .edit_asset{
    background:url(../assets/images/library/edit_white.png) no-repeat
}

.dell-item .info .row_2 .edit_asset_video{
    background: url('../assets/images/library/edit_video.png') no-repeat;
}

.dell-item .info .row_2 .edit_asset_app{
    background: url('../assets/images/library/edit_app.png') no-repeat;
}

.dell-item .info .row_2 .edit_asset_guides{
    background: url('../assets/images/library/edit_guides.png') no-repeat;
}

.dell-item .info .row_2 .edit_asset_guides{
    background: url('../assets/images/library/edit_guides.png') no-repeat;
}

.dell-item .info .row_2 .edit_asset_templates{
    background: url('../assets/images/library/edit_templates.png') no-repeat;
}


.dell-item .info .row_2 .counter_asset{
    background:#fff;
    border-radius:50%;
    height:19px;
    width:19px;
    position:absolute;
    margin-left:2px
}
.dell-item .info .row_2 .counter_asset .circle_counter .text_counter{
    position:relative;
    top:3px;
    font-size:14px;
    font-weight:500;
    border:none!important;
    text-align:center;
    letter-spacing:-1px
}
.dell-item .info .row_2 .counter_asset .circle_counter .text_counter_more_10{
    position:relative;
    top:-1px;
    font-size:15px;
    font-weight:500;
    border:none!important;
    text-align:center;
    letter-spacing:-1px;
    display:none
}
.dell-item .img_container img{
    margin-top:6px
}
//.dell-item.item-image div.info{
 //   background:#0085c3
//}
.dell-item.item-image div.row_2 .counter_asset .circle_counter .text_counter{
    color:#0085c3
}
.dell-item.item-image div.row_2 .counter_asset .circle_counter .text_counter_more_10{
    color:#0085c3
}
//.dell-item.item-video div.info{
   // background:#b7295a
//}
.dell-item.item-video div.row_2 .counter_asset .circle_counter .text_counter{
    color:#b7295a
}
.dell-item.item-video div.row_2 .counter_asset .circle_counter .text_counter_more_10{
    color:#b7295a
}
//.dell-item.item-app div.info{
   // background:#7ab800
//}
.dell-item.item-app div.row_2 .counter_asset .circle_counter .text_counter{
    color:#7ab800
}
.dell-item.item-app div.row_2 .counter_asset .circle_counter .text_counter_more_10{
    color:#7ab800
}
//.dell-item.item-guides div.info{
   // background:#f2af00
//}
.dell-item.item-guides div.row_2 .counter_asset .circle_counter .text_counter{
    color:#f2af00
}
.dell-item.item-guides div.row_2 .counter_asset .circle_counter .text_counter_more_10{
    color:#f2af00
}
//.dell-item.item-templates div.info{
 //   background:#71c6c1
//}
.dell-item.item-templates div.row_2 .counter_asset .circle_counter .text_counter{
    color:#71c6c1
}
.dell-item.item-templates div.row_2 .counter_asset .circle_counter .text_counter_more_10{
    color:#71c6c1
}
.checkbox-icon{
    display: inline-block;
    width: 5px;
    height: 20px;
    background-color: transparent;
    margin-right: 10px;
    background: center url(../assets/images/library/refine.png) no-repeat;
    position: absolute;
    left: 15px;
    top: 10px;
}
.plus-icon{
    display: inline-block;
    width: 20px;
    height: 20px;
    background-color: transparent;
    margin-right: 10px;
    background: center url(../assets/images/library/plus.png) no-repeat;
    position: absolute;
    right: 0;
    top: 10px;
}
.minus-icon{
    display: none;
    width: 20px;
    height: 20px;
    background-color: transparent;
    margin-right: 10px;
    background: center url(../assets/images/library/minus.png) no-repeat;
    position: absolute;
    right: 0;
    top: 10px;
}
.no-padding{
  padding: 0;
}

.no-margin{
  margin: 0;
}

.pr-15{
  padding: 0 15px 0 0;
}
.prl-3 {
  padding: 0 3px 0 3px;
}

.radiogrid{
    float: right;
    padding-left: 10px;
    padding-top: 11px;
}
.radiogrid label {
    display: inline-block;
    cursor: pointer;
    position: relative;
    padding-left: 25px;
    margin-right: 0;
    font-size: 13px;
}
.radiogrid input[type=radio],
.radiogrid input[type=checkbox] {
    display: none;
}
.radiogrid .gridview:before {
    content: "";
    display: inline-block;

    width: 17px;
    height: 17px;

    margin-right: 0;
    position: absolute;
    left: 0;
    bottom: 1px;
    background-color:transparent;
    background: center url(../assets/images/library/gridview_inactive.png) no-repeat;
}
.radiogrid .listview:before {
    content: "";
    display: inline-block;

    width: 17px;
    height: 17px;

    margin-right: 0;
    position: absolute;
    left: 0;
    bottom: 1px;
    background-color:transparent;
    background: center url(../assets/images/library/listview_inactive.png) no-repeat;
}
.radiogrid label:before {
    border-radius: 0;
}
.radiogrid input[type=radio]:checked + .gridview:before {
    background: center url(../assets/images/library/gridview.png) no-repeat;

}
.radiogrid input[type=radio]:checked + .listview:before {
background: center url(../assets/images/library/listview.png) no-repeat;
}
.list-dell-item {
    border-bottom: 1px solid black;
    padding: 5px 10px 5px;
    width: 100%;
    #container_buttons{
        z-index: 1;
        width: 130px;
        right: 0;
    }
    .deleteContent{
        margin-right: 0;
        float: right;
        margin-left: 0;
    }
    .containerImgThumb{
        width: 50px;
        height:50px;
        float: left;
    }
    .info{
        float: right;
        width: calc(~'100% - 50px');
        height: 50px;
        max-height: 50px;
        min-height: 50px;
        .row_1, .row_2{
            width: 50%;
            height: 50px;
            float: left;
            border-bottom: none;
        }
        .row_2 ul{
            padding-right: 70px;
            margin-top: 14px;
            min-width: 210px;
            float: right;
        }
        .row_1 .nameAsset{
            float: right;
            text-align: left;
            width: calc(~'100% - 50px')!important;
            margin-left: 0;
            margin-right: 0;
            margin-top: 20px;
        }
        .row_1 .img_container{
            float: left;
            text-align: left;
            width:50px;
            padding: 5px 10px 0 10px;
            margin: 0;
        }
        .row_2 .counter_asset{
            height: 16px;
            width: 16px;
            .circle_counter .text_counter {
                top: 2px;
                left: -1px;
            }
        }
        .row_1 .tooltip .tooltip-arrow{
            margin-left:-450px!important
        }
    }
}
.row{
    .open p {
        border-top: 1px solid rgba(0,0,0,.15);
        border-left: 1px solid rgba(0,0,0,.15);
        border-right: 1px solid rgba(0,0,0,.15);
        border-bottom: 1px solid #fff;
    }
}
ui-carousel{
    margin-bottom: 50px !important;
    height: 64px;
    width: 98%;
    margin-left: 10px;
    .carousel-wrapper, .track-wrapper, .track, .carousel-item{
        height: 190px;
        img{
            height: 190px;
            width: 100%;
        }
    }
}
header {
    position: fixed;
    top: 0;
    z-index: 999;
    background-color: #fff;
    width: 100%;
}
.scrolled-nav {
      height: 95px !important;
      line-height: 95px !important;
      }
.shrink{
height:95px !important;
transition: all 0.4s ease-in-out !important;
-webkit-transition: all 0.4s ease-in-out !important;
-moz-transition: all 0.4s ease-in-out !important;
}
.notShrink{
height:190px !important;
transition: all 0.4s ease-in-out !important;
-webkit-transition: all 0.4s ease-in-out !important;
-moz-transition: all 0.4s ease-in-out !important;
}
.marginContainer{
  margin-top:47px !important;
  transition: all 0.4s ease-in-out !important;
  -webkit-transition: all 0.4s ease-in-out !important;
  -moz-transition: all 0.4s ease-in-out !important;
}
.notmarginContainer{
  margin-top:130px !important;
  transition: all 0.4s ease-in-out !important;
  -webkit-transition: all 0.4s ease-in-out !important;
  -moz-transition: all 0.4s ease-in-out !important;
}
.hideHeader{
  opacity: -1;
}
.ui-carousel .carousel-next .carousel-btn{
  right: 8px !important;
  color: #fff;
}
.ui-carousel .carousel-prev .carousel-btn{
  left: 8px !important;
  color: #fff;
}
#searchBar button:hover{
  background: #02a5f5 !important;
}
.dell-item .info .row_1 img{
  float: right;
  margin-right: 4px;
  margin-top: 8px;
}
#folderWrap .folderContainer .folderBottom a{
  color: #fff;
}
#folderWrap .folderContainer .folderBottom a:hover{
  color: #c0d0df;
}
.fileName img{
float: right;
    clear: right;
    margin-top: -42px;
    margin-right: 24px;
    height: 40px;
    width: 46px;
  }
.closeBanner{
  cursor: pointer;
    width: 39px;
    position: absolute;
    right: 11px;
    z-index: 10;
    margin-top: 1px;
}
.bannerLarge{
  margin-top: 75px;
  margin-bottom: -140px;
}
.bannerSmall{
  margin-top: 140px;
  z-index: 1;
  margin-bottom: -20px;
}
.arrowLeft {
  width: 30px !important;
  height: 30px !important;
  margin-top: 80px;
  position: absolute;
  margin-left: 32px;
  left: inherit;
}
.arrowRight{
  width: 30px !important;
  height: 30px !important;
  margin-top: 80px;
  position: absolute;
  left: 1107px;
}

#myCarousel2 .carousel-control {
  background: none;
  width: 8%;
  .arrowRight {
    left: 35%;
  }
}

@media screen and (max-width: 768px) {
  #myCarousel2 .carousel-control.right {
    .arrowRight{
      margin-top: 64px !important;
      left: 35%;
    }
  }

  #myCarousel2 .carousel-control.left {
    .arrowLeft{
      margin-top: 64px !important;
      right: 35%;
    }
  }
}

.svgArrows {
filter: brightness(0) invert(1);
}
.circle_counter {
font-size: 15px;
margin-top: -4px;
}
.shareAsset {
content:'';
display:inline-block;
height:1em;
width:1em;
background-image:url('../assets/images/network-share-grey-64.svg');
background-size:contain;
background-repeat:no-repeat;
}
.shareAsset:hover {
content:'';
display:inline-block;
height:1em;
width:1em;
background-image:url('../assets/images/network-share-grey-hover-64.svg');
background-size:contain;
background-repeat:no-repeat;
}
.downloadAsset {
content:'';
display:inline-block;
height:1em;
width:1em;
background-image:url('../assets/images/download-grey-64.svg');
background-size:contain;
background-repeat:no-repeat;
}
.downloadAsset:hover {
content:'';
display:inline-block;
height:1em;
width:1em;
background-image:url('../assets/images/download-grey-hover-64.svg');
background-size:contain;
background-repeat:no-repeat;
}
.editAsset {
content:'';
display:inline-block;
height:1em;
width:1em;
background-image:url('../assets/images/pencil-grey-64.svg');
background-size:contain;
background-repeat:no-repeat;
}
.editAsset:hover {
content:'';
display:inline-block;
height:1em;
width:1em;
background-image:url('../assets/images/pencil-grey-hover-64.svg');
background-size:contain;
background-repeat:no-repeat;
}
.dell-item.item-image div.row_2 .counter_asset .circle_counter .text_counter{
  font-size: 15px;
  color: #636363;
}
.dell-item.item-video div.row_2 .counter_asset .circle_counter .text_counter{
  font-size: 15px;
  color: #636363;
}
.dell-item.item-guides div.row_2 .counter_asset .circle_counter .text_counter{
  font-size: 15px;
  color: #636363;
}
.dell-item.item-templates div.row_2 .counter_asset .circle_counter .text_counter{
  font-size: 15px;
  color: #636363;
}
.dell-item.item-app div.row_2 .counter_asset .circle_counter .text_counter{
  font-size: 15px;
  color: #636363;
}
#myCarousel2 {
  .carousel-inner {
    position: relative;
    > .item{
      > img{
        width: 100%;
        min-height: 150px;
      }
    }
  }
}

.item {
    width: 100%;
  }
  .carousel-control {
  position: absolute;
  //top: 50%; /* pushes the icon in the middle of the height */
  z-index: 5;
  display: inline-block;
  opacity: 1;
  }
.imgAssetTitle{
  float: right;
    clear: right;
    margin-top: -16px;
    height: 40px;
    width: 46px;
    position: absolute;
    right: -4px;
}

#share {
  cursor: pointer;
}

.contentFilter {
  form {
    div {
      margin-bottom: 1em;

      input {
        width: 100%;
      }
    }
    div:nth-child(3) {
      margin-bottom: 0;
    }

    #filter {
      float: right;
      margin-top: 5px;
      cursor: pointer;
      color: #669933 !important;
    }
  }
}

.app-modal-window-folder .modal-dialog{
  width: 400px;
  .closeModal{
    left: -12px;
    top: -8px;
    position: relative;
  }
  #folderWrap {
    .documents {
      ul.folders__list{
        height: 416px;
      }
    }
  }
}

.folderList {
  p {
    margin-bottom: 10px;
    span {
      font-size: 16px;
      color: #c92466;
      margin-left: 20px;
    }
  }
}
