.item {
    // min-width: 215px;
    // /*min-height: 215px;*/
    // max-width: 215px;
    float: left;
    margin: 0 20px 28px 0;
    position: relative;
    padding: 0!important;
    cursor: pointer;

    a {
        color: none;
    }

    a:hover {
        font-style: none;
    }

    .containerImgThumb {
        background: none center center no-repeat;
        background-size: cover;
    }

    .info {
        position: relative;
        bottom: 0;
        width: 100%;
        height: 78px;
        max-height: 78px;
        min-height: 78px;
        padding-left: 0;
        padding-right: 0;

        p {
            color: #454545;
            float: left;
            font-family: @secondaryFont500;
            font-size: 12px;
            overflow: hidden;
            width: 140px;
        }

        span {
          font-size: 14px;
          position: absolute;
          bottom: 5px;
            right: 10px;
        }

        span2 img {
          width:20px;
          height: 27px;
          margin: 14px 10px 0 13px;
          float: left;
        }

        .description {
            margin-top: 10px;
        }

        .row_1 {
            padding-left: 0;
            padding-right: 0;
            height: 43px;
            max-height: 43px;
            min-height: 43px;

            .tooltip {
                width: 100%;
                left: 5px!important;
                word-wrap: break-word;
                top: 33px!important;

                .tooltip-inner {
                    width: 100%;
                    background-color: #ffffff!important;
                    color: #000;
                    overflow: hidden;
                }

                .tooltip-arrow {
                    border-bottom-color: #ffffff!important;
                    margin-left: -50px!important;
                }

                .tooltip.bottom .tooltip-arrow {
                    top: 0;
                    left: 50%;
                    margin-left: -5px;
                    border-width: 0 5px 5px;
                     border-bottom-color: transparent!important;
                    background-color: #ffffff!important;
                    font-family: @secondaryFont500;
                    text-shadow: 1px 1px 1px #000;

                }
            }

            .nameAsset {
                word-wrap: break-word;
                width: 153px!important;
                height: 25px;
                font-size: 14px;
                padding: 0;
                margin-right: 8px;
                margin-left: 12px;
                float: left;
                margin-top: 12px;
                font-family:  @secondaryFont500;
                overflow: hidden;
                line-height: 1;
                &.long {
                    margin-top: 12px;
                }
            }

        }

        .row_2 {
            padding-left: 0;
            padding-right: 0;
            height: 34px;
            max-height: 34px;
            min-height: 34px;
            overflow: hidden;
            border-bottom: 1px solid rgba(51,51,51,.13);
            ul {
                position: relative;
                bottom: 0px;
                margin-left: 12px;
                margin-top: 7px;
            }

            li {
                display: inline-block;
                width: 17px;
                height: 17px;
                margin-right: 12px;

            }

            .hideAssetCount{
                display: none!important;
            }
            .showAssetCount{
                display: block!important;

            }
            .text_counter_plus{
                position: relative;
                top: 3px;
                font-size: 9px;
                font-weight: 500;
                border: none!important;
                text-align: center;
                letter-spacing: -0.2px;
                transform: scaley(1.2);
                display: none;
            }

            .download_asset {
                background: url('../assets/images/library/download_file_images.png') no-repeat;
                a {
                    width: 16px;
                    height: 16px;
                    position: absolute;
                }
            }

            .share_asset{
                background: url('../assets/images/library/sharing-interface_images.png') no-repeat;
            }

            .edit_asset{
                background: url('../assets/images/library/edit_white.png') no-repeat;
            }

            .counter_asset {
                background: #fff;
                border-radius: 50%;
                height: 19px;
                width: 19px;
                position: absolute;
                margin-left: 2px;

               .circle_counter {



                   .text_counter {
                       position: relative;
                       top: 3px;
                       font-size: 15px !important;
                       font-weight: 500;
                       border: none!important;
                       text-align: center;
                       font-family: 'Roboto',sans-serif;
                       letter-spacing: -1px;


                   }
                   .text_counter_more_10{
                       position: relative;
                       top: 3px;
                       font-size: 15px !important;
                       font-weight: 500;
                       border: none!important;
                       text-align: center;
                       font-family: 'Roboto', sans-serif;
                       letter-spacing: -1px;
                       display: none;
                   }
               }

        }

        }
    }

    .img_container img{
        margin-top: 6px;
    }

    &.item-image {
        div.info {
            // background: @colorImage;
        }
        div.row_2{
            .counter_asset{
                .circle_counter{

                    .text_counter{
                        color: @colorImage;
                    }
                    .text_counter_more_10{
                        color: @colorImage;
                    }
                }
            }
        }
    }

    &.item-video {
        div.info {
            // background: @colorVideo;
        }
        div.row_2{
            .counter_asset{
                .circle_counter{

                    .text_counter{
                        color: @colorVideo;
                    }
                    .text_counter_more_10{
                        color: @colorVideo;
                    }
                }
            }
        }
    }

    &.item-guides {
        div.info {
            // background: @colorGuides;
        }
        div.row_2{
            .counter_asset{
                .circle_counter{

                    .text_counter{
                        color: @colorGuides;
                    }
                    .text_counter_more_10{
                        color: @colorGuides;
                    }
                }
            }
        }
    }

    &.item-templates {
        div.info {
            // background: @colorTemplates;
        }
        div.row_2{
            .counter_asset{
                .circle_counter{

                    .text_counter{
                        color: @colorTemplates;
                    }
                    .text_counter_more_10{
                        color: @colorTemplates;
                    }
                }
            }
        }
    }

}
