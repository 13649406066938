.privacyContainer {
    background: #ededed;
    height: auto;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;

    #logo {
        line-height: 22px;
        margin-left: -15px;
        font-size: 16px;
        display: block;
        width: auto;
        margin-right: 0;
        overflow: hidden;

        img {
            float: left;
            margin: 40px 20px;
            width: 130px;
        }
        p {
            float: left;
            color: #0685c3;
            font-size: 17px;
            font-family: @secondaryFont300;
            margin-top: 63px;
        }
        .title {
            margin-right: 330px;
            font-size: 28px;
        }
        .welcome {
            margin-right: 30px;
        }

        a{
            margin-right: 10px;
        }
    }

    .privacy-label {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 140px;
    }

    #privacyText{
        padding: 10px;

        ul{
            margin-left: 20px;
        }

        p{
            text-align: justify;
            margin-top: 10px;
        }
    }

   
}