#landingCarousel {
  height: 204px;
  width: 100%;
  position: relative;

  @media (min-width: 320px) {
    height: 181px;
  }

  @media (min-width: 426px) {
    height: 584px;
  }

  @media (min-width: 769px) {
    height: 584px;
  }

  .slides {
    > div {
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0;
      height: auto;
      width: 100%;
      -moz-transition: opacity 500ms ease;
      -ms-transition: opacity 500ms ease;
      -o-transition: opacity 500ms ease;
      -webkit-transition: opacity 500ms ease;
      transition: opacity 500ms ease;

      @media (min-width: 769px) {
        height: 584px;
      }

      > img {
        width: 100%;
        height: auto;

        @media (min-width: 768px) {
          height: 100%;
        }
      }

      &.active {
        opacity: 1;
      }

      .details {
        width: 350px;
        cursor: default;
        position: absolute;
        bottom: 65px;
        left: 20px;

        @media (min-width: 426px) {
          bottom: 155px;
        }

        @media (min-width: 769px) {
          bottom: 240px;
        }

        h2, p {
          color: #fff;
        }

        h2 {
          font-size: 30px;
          font-family: @secondaryFont300;
          margin-bottom: 15px;

          @media (min-width: 426px) {
            font-size: 39px;
          }
        }

        p {
          font-size: 17px;
          font-family: @secondaryFont100;
          line-height: 1.3;
        }
      }
    }
  }

  .indicators {
    position: absolute;
    left: 20px;
    top: 160px;

    @media (min-width: 426px) {
      top: 235px;
    }

    @media (min-width: 769px) {
      top: 352px;
    }

    .visually-hidden {
      position: absolute;
      left: -10000px;
      top: auto;
      width: 1px;
      height: 1px;
      overflow: hidden;
    }

    > div {
      width: 9px;
      height: 9px;
      border: 1px solid #fff;
      border-radius: 20px;
      margin: 3px;
      cursor: pointer;
      float: left;

      &.active {
        background: #fff;
      }

      &:focus {
        outline: 2px solid #c0c0c0;
      }
    }
  }
}
