#myCarousel1 {
    margin-top: 95px;
    .showBanner {
        cursor: pointer;
        text-decoration: underline;
        background-color: #0a7eb9;
        text-align: center;
        width: 100%;
        height: 48px;
        font-size: 1.3em;
        color:#dfecf3;
        font-family: 'Roboto', sans-serif;
        padding-top: 10px;
    }
}
#fileInputContainer {
    ul.dropdown-menu {
        background-color: white;
        border: 1px solid #ccc;
        position: absolute;
    }
    ul.dropdown-menu li {
        background-color: white;
        margin-bottom: 0;
        height: 27px;
        text-transform: capitalize;

        a {
            font-family: "Roboto", sans-serif;
            font-size: 13px;
        }
    }
    .colorAutocomplete {
        text-transform: capitalize;
    }
}

.app-modal-window-addContent {
    @media (max-width: 991px){
        .modal-dialog {
            width: 100%;
        }
    }
    @media (min-width: 992px){
        .modal-dialog {
            width: 70%;
        }
    }
    .row {
        margin-left: 0;
    }
    .modal-body {
        padding: 30px 50px;
    }

    .biggerModal {
        height: auto !important;
    }

    .container-border {
        border: 1px solid #ccc;
        padding: 15px;
    }

    .btn-primary {
        background: #669933;
        min-width: 60px;
        border: none;

        &:hover {
            background: #8cbf31;
        }
    }

    .errorMessage {
        position: static;
    }

    .accordionDetail {
        border-bottom: none;
    }

    .formAddContent{
        width: 100%;

        .titleContainer {
            float: none;
            margin-left: 0;
        }

        .choosethumbnail {
            margin-top: 25px;
            position: relative;

            &.error {
                margin-bottom: 35px;

                .errorMessage {
                    position: absolute;
                    bottom: -27px;
                    left: 0;
                }
            }

            .preview-section{
                min-height:70px;
                padding: 1px;
            }

            label {
                font-family: @secondaryFont300;
                margin-top: 8px;

                > span:first-child {
                    margin: 1px 5px 0 0;
                }
            }

            input {
                cursor: pointer;
            }

            .choosethumbnailBoxes span {
                float: left !important;
                margin-top: 3px;

            }
        }

        input:-webkit-input-placeholder {
            color: #CCCCCC;
        }

        input:-moz-placeholder {
            color: #CCCCCC;
        }

        input:-ms-input-placeholder {
            color: #CCCCCC;
        }

        ul li {
            list-style: none;
            margin-bottom: 15px;
            width: 100%;
        }

        select {
            border: solid 1px #CCCCCC;
            height: 30px;
            -moz-appearance:none;
            -webkit-appearance:none;
            background: url('../assets/images/admin/select_down.png') no-repeat right;
            padding-left: 8px;
        }

        select::-ms-expand {
            display: none;
        }

        select option:first-child {
            color: #CCCCCC;
        }

        select:disabled {
            color: #CCCCCC;
        }

        select option {
            color: black;
        }

        p {
            margin-bottom: 8px;
            font-size: 17px;
            font-family: @secondaryFont500;
            color: #454545;

            span {
                float: none !important;
                color: #949494;
                font-size: 14px;
            }
        }

        textarea {
            border: 1px solid #ccc;
        }

        #imgPreview {
            width: 70px;
            height: 70px;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
        }

        #imgPreviewEmpty {
            width: 70px;
            height: 70px;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
        }

        .errorMessage{
         bottom: -5px;
        }

        .description{
            position: relative;
            .errorMessage{
                bottom:-25px;
            }
        }

        .success{
            div{
                width:255px;
                margin: 100px auto;
            }
        }

        #okButton{
            margin-right:30px;
        }
    }

    #addContentTitle {
        text-align: left;
        font-family: @secondaryFont300;
    }

    #contentName {
        padding: 4px 8px;
        width: 100%;
    }

    #uploadFile {
        position: relative;
    }

    #fileInputContainer {
        position: relative;
        padding-top: 28px;

        .fileList{
            height: 150px;
            border: 1px solid #ccc;
            overflow-y: scroll;
            input {
                width: 100%;
            }
            select {
                width: 100%;
                height: 28px;
            }
        }

        #realInput {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            opacity: 0;
            width: 100%;
            height: 30px;
            cursor: pointer;

            &:hover + #fakeInput input:last-child {
                background: #8cbf31;
            }
        }

        #fakeInput {
            position: absolute;
            top: 0;
            left: 0;

            input{
                height: 30px;
                float: left;
            }

            input:first-child {
                padding-left: 10px;

            }

            input:last-child {
                border-bottom-left-radius: 0;
                border-top-left-radius: 0;
            }
        }

        .selectedFiles {
            color: #669933;
            font-size: 14px;
            cursor: pointer;
            margin-bottom: 0px;

            i {
                font-style: normal;
            }

            &+div {
                padding-left: 12px;

                 span {
                    float: none;
                    display: block;
                    cursor: default;
                    font-size: 12px;
                    color: #949494;

                    i {
                        display: block;
                        width: 13px;
                        height: 14px;
                        padding: 5px;
                        margin-right: 4px;
                        float: left;
                        background: url("../assets/images/admin/close_window.png") center center no-repeat;
                        background-size: 8px 8px;
                        cursor: pointer;
                    }
                }
            }
        }



        .errorMessage {
            position: absolute;
            top: 30px;
        }
    }

    #realThumbnailInput {
        opacity: 0;
        z-index: 1;
        width: 83px !important;
        height: 33px;
        left: 43px;
        top: 18px;
        position: absolute;
        cursor: pointer;

        &[disabled] {
            cursor: default !important;

            &::-webkit-file-upload-button {
                cursor: default;
            }
        }

        & + #fakeThumbnailInput {
            cursor: pointer;
            text-align: center;
            width: 80px;
            margin: 18px auto 0;

            button {
                padding: 6px 20px;
                background: #0685c2;

                &[disabled] {
                    background: #aeb2b8 !important;
                    color: #7b7b7b !important;
                }
            }
        }

        &::-webkit-file-upload-button {
            cursor: pointer;
        }

        &:hover + #fakeThumbnailInput button {
            background: #0d9ed3;
        }
    }

    #contentType {
        overflow: hidden;
        padding-right: 0;

        #selectedContentType {
            span:first-child {
                width: 27px;
                height: 27px;
                float:none;
                display: inline-block;
                border-radius: 30px;
                margin: 2px;
                background-size: 23px;
                background-repeat: no-repeat;
                background-position: center;
            }

            span.selectedLabel{
                height: 30px;display: inline-block;float: none;position: relative;top: -10px;text-transform: uppercase;
            }
        }

        ul li {
            float: none;
            width: auto;
            margin: 0 20px 0 2px;
            cursor: pointer;

            &:hover p, &.active p{
                font-family: 'Roboto', sans-serif;
                color: #454545;
            }

            > span:first-child {
                background-position: center;
                background-repeat: no-repeat;
                width: 35px;
                height: 35px;
                display: block;
                float: left;
                border-radius: 100%;
                transition: background 300ms ease;
                background-size: 35px;
            }

            &.active.image > span:first-child {
                background-color: @colorImage;
            }

            &.active.video > span:first-child {
                background-color: @colorVideo;
            }

            &.active.guidelines > span:first-child {
                background-color: @colorGuides;
            }

            &.active.templates > span:first-child {
                background-color: @colorTemplates;
            }

            &.active.rplg > span:first-child {
                background-color: @colorRplg;
            }

            &.active.rcag > span:first-child {
                background-color: @colorRcag;
            }

            &.active.digital_activation > span:first-child {
                background-color: @colorEngagement;
            }

            &.active.campaign > span:first-child {
                background-color: @colorCampaign;
            }

            &.active.toolkit > span:first-child {
                background-color: @colorToolkit;
            }

            &.active > span:first-child {
                background-image: url('../assets/images/fileTypes/active_icon_big.png') !important;
                background-position: center !important;
            }

            p {
                color: #888787;
                float: left;
                margin: 15px 0 0 6px;
                font-family: 'Roboto', sans-serif;
                text-transform: uppercase;
                font-size: 14px;
                min-width: 65px;
                border: none;
            }
        }
    }

    #firstChild, #secondForm, .formAddContent > .success {
        padding-bottom: 75px;
    }

    .filterSelector {
        background: white;
        padding-left: 10px;
        height: 240px;
        overflow-y: auto;
        position: absolute;
        border: 1px solid #ccc;
        width: 758px;
        margin: -1px 0 15px 0;
        z-index: 10;

        p {
            font-size: 13px;
            font-weight: 600;
            margin-top:10px;
        }

        ul {
            display: none;
            padding-left: 15px;

            li {
                margin-bottom: 0;
            }
        }

        ul.level0, ul.level1 {
            display: block;
            padding-left: 0;
        }

        ul.level0 > li {
            border-bottom: 1px solid #ccc;

            &:last-child {
                border-bottom: none;
            }
        }

        .accordionDetail{
            span{
                float: left !important;
                margin-right: 5px;
            }

            label{
                margin-top: 5px;
            }
        }

        label, p {
            color: #6e6e6e;
        }

        p {
            font-size: 15px;
            text-transform: uppercase;
        }

        span.filterCheckBox {
            margin-left: 0;
            margin-top: 0;
        }
    }

    .button1.addContentModalButton{
        min-width: 70px;
        margin: 20px 0 45px 0;
    }

    #addContentModalButton{
        margin: 28px 0 45px 0;
        padding: 6px 25px;
    }

    .button2 {
        margin-left: 0;
    }
}


.app-modal-window-addFolder {

    .modal-dialog {
        width: 300px;
    }

    .container-border {
        border: 1px solid #ccc;
        padding: 15px;
    }

    .btn-primary {
        background: #669933;
        min-width: 65px;
        border: none;

        &:hover {
            background: #8cbf31;
        }
    }

    .errorMessage {
        position: static;
    }

    .accordionDetail {
        border-bottom: none;
    }

    .formAddContent{
        width: 100%;

        .titleContainer {
            float: none;
            margin-left: 0;
        }

        input:-webkit-input-placeholder {
            color: #CCCCCC;
        }

        input:-moz-placeholder {
            color: #CCCCCC;
        }

        input:-ms-input-placeholder {
            color: #CCCCCC;
        }

        ul li {
            list-style: none;
            margin-bottom: 15px;
            width: 100%;
        }



        p {
            margin-bottom: 8px;
            font-size: 17px;
            font-family: @secondaryFont500;
            color: #454545;

            span {
                float: none !important;
                color: #949494;
                font-size: 14px;
            }
        }

        .errorMessage{
         bottom: -5px;
        }

        .description{
            position: relative;
            .errorMessage{
                bottom:-25px;
            }
        }

        .success{
            div{
                width:255px;
                margin: 100px auto;
            }
        }

        #okButton{
            margin-right:30px;
        }
    }

    #addFolderTitle {
        text-transform: uppercase;
        text-align: left;
        font-family: @secondaryFont300;
    }

    #folderName {
        padding: 4px 8px;
        width: 520px;
    }

    .addFolderModalButton{
        margin: 20px 0 45px 0;
    }

    #addFolderModalButton{
        margin: 28px 0 45px 0;
        padding: 6px 25px;
    }

    .button2 {
        margin-left: 0;
    }
}
