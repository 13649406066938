.confirmationFooter{
    height: 90px;
    position: relative;
    div:first-child{
        margin: auto;
        width: 281px;       
    }
    p{
        margin-top: 10px;
    }
}
#confirmationTitle {
    color: #0685c3;
    p {
        font-size: 12px;
        margin-top: 0;
    }
    h1 {
        font-size: 18px;
    }
}
.confirmationInfo {
    font-size: 14px;
    color: #333333;
    margin-bottom: 20px;
    span {
        font-size: 12px;
        color: #333333;
    }
}

.formConfirmation {
    width: 207px !important;
}

#confirm-modal {
    .modal-header {
        border-bottom: none;
        h4 {
            color: #c92466;
        }
    }
    .modal-footer {
        border-top: none;
    }
}

.retail-container {
    padding: 0;
}

.logo-confirmation-page {
    height: 130px !important;
}

.admin-nav {
    display: flex !important;
    align-items: center;
}

.d-block {
    display: flex;
    align-items: center;
    justify-content: center;

    .retail-name {
        margin-top: 0 !important;
        font-size: 18px !important;
        line-height: 40px;
    }
}

.header-admin {
    position: unset;
    height: 77px;
    display: flex;
    align-items: center;
}

.retail-name-container {
    text-align: left;
    cursor: default;
}