.loginContainer, .confirmationContainer {
    background: #ededed;
    height: auto;
    padding-bottom: 10px;

    #logo {
        line-height: 22px;
        font-size: 16px;
        width: auto;
        margin: 0;
        overflow: hidden;
        height: auto;
        display: block;

        @media (min-width: 768px) {
            margin: auto;
            display: flex;
            height: 190px;
        }

        img {
            float: left;
            width: 95px;
            margin-left: 0;

            @media (min-width: 769px) {
                width: 130px;
            }

            @media (min-width: 993px) {

            }
        }
        p {
            float: left;
            color: #0685c3;
            font-size: 14px;
            font-family: @secondaryFont300;
            margin-top: 0;

            @media (min-width: 769px) {
                font-size: 18px;
            }
        }
        .title {
            font-size: 20px;
            @media (min-width: 769px) {
                font-size: 28px;
            }
        }
        .welcome {
            margin-right: 30px;
        }
        .emailabel {
            color: #444444;
            margin-top: 30px;
            font-size: 14px;
            line-height: 18px;

            @media (min-width: 767px) {
                margin-top: 6px;
            }
        }

        .col-center {
            justify-content: center;
        }

        .formLogin {
            float: left;
            width: 100%;
            // margin-top: 5px;
            .button2 {
                margin: 10px 0 0 0;
                padding: 0 10px;
                font-family: @secondaryFont700;
            }
            .buttonEnter {
                margin: 4px 0 0 0;
                padding: 0 10px;
                font-family: @secondaryFont700;
                height: 60px;
            }
            .errorMessage {
                margin-top: 0px;
                clear: both;
                display: block;
            }
            .errorMessageButton {
                margin-top: 25px;
            }

            .privacyPolicies{
                font-size: 13px;
                margin-top: 10px;
                margin-bottom: 10px;

                a{
                    font-size: 13px;
                    color: #336A9A;

                    &:focus {
                        color: #2a6496;
                        text-decoration: underline;
                        outline: none;
                    }
                }
            }

            input {
                &:focus {
                    outline: 2px solid #769cff;
                }
            }
        }

        .d-flex {
            display: flex;
            justify-content: flex-start;
            align-items: baseline;


            @media only screen and (min-width: 768px) {
                justify-content: end;
                align-items: center;
            }
        }

        .d-flex-col {
            display: flex;
            align-items: center;
        }

    }
}