#loadingbackground{
    background-color:#696969;
    opacity:0.8;
    width: 100%;
    height: 100%;
    position: absolute;
    margin: -20px;
    text-align: center;
    z-index: 10;
    h2 {
        color: white;
    }
}

.lds-spinner {
    margin-top: 250px;
    color: white;
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
}

.lds-spinner div {
    transform-origin: 32px 32px;
    animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
    content: " ";
    display: block;
    position: absolute;
    top: 3px;
    left: 29px;
    width: 5px;
    height: 14px;
    border-radius: 20%;
    background: #fff;
}
.lds-spinner div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
}
@keyframes lds-spinner {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.content-details {
    font-family: @secondaryFont500;
    padding-top: 20px;

    i {
        &.share,
        &.download,
        &.add,
        &.filter-icon {
            background-repeat: no-repeat;
            background-position: center;
        }

        &.share {
            background-image: url('../assets/images/library/details/share.png');
        }
        &.download {
            background-image: url('../assets/images/library/details/download.png');
        }
        &.add {
            background-image: url('../assets/images/library/details/add.png');
        }
    }

    
    .nav {
        a {
            color: #000000;
        }
        
    > li {
            > a {
                border-radius: 0;
                background-color: @gray-1;
            }

            &.active {
               > a {
                    background-color: @blue-1;
                }
            }
        }
    }

    .preview-container {
        border: 1px solid @black; 
        margin-bottom: 20px;

        video {
            max-width: 100%;
        }
    }

    .preview-image {
        height: 265px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }

    .content-info {
        max-height: 290px;
        overflow: hidden;
        overflow-y: auto;
        
        .filename {
            font-size: 20px;
            font-weight: bold;
            margin-bottom: 20px;

            &-underline:after {
              content: '';
              width: 92%;
              height: 20px;
              border-bottom: 1px solid @gray-3;
              margin: 0 15px;  
            }
        }

        .img-title {
            float: right;
            height: 35px;
        }

        .description {
            font-size: 12px;
        }

        .tag {
            display: inline-block;
            padding: 4px 5px;
            color: @gray-2;
            border-radius: 6px;
            border: solid 1px @gray-1;
            margin-right: 4px;
            margin-bottom: 4px;            
            font-size: 12px;
        }

        .description,
        .tags,
        .exp-date,
        .license {
            margin-bottom: 15px;
        }
    }

    .documents,
    .folders {
        &__list {
            list-style: none;
            overflow-y: auto;
        }

        &__item {
            .actions {
                float: right;
                
                i {
                    display: inline-block;
                    width: 26px;
                    height: 29px;
                    cursor: pointer;
                }
            }
        }

        .share:hover {
            background-image: url('../assets/images/library/details/share_hover.png');
        }

        .download:hover {
            background-image: url('../assets/images/library/details/download_hover.png');
        }

        .add {
            &:hover {
                background-image: url('../assets/images/library/details/add_hover.png');
            }

            &.active {
                background-image: url('../assets/images/library/details/add_active.png');

                &:hover {
                    background-image: url('../assets/images/library/details/add_active_hover.png');
                }
            }
        }
    }

    .folders {
        span {
            color: @red-1;
            font-weight: bold;
            text-transform: uppercase;
        }

        &__list {
            height: 530px;
            background-color: @gray-0;
        }

        &__item {
            color: @gray-4;
            border-bottom: 1px dashed #e5e6e6;
            padding: 10px 5px 10px 45px;

            .name {
                display: inline-block;
            }
        }
        
    }

    .tab {
        .filter-icon {
            background-image: url("../assets/images/library/filters-grey.png");
            background-size: 85%;
            width: 26px;
            height: 100%;
            display: block;
        }

        &.active {
            .filter-icon {
                background-image: url("../assets/images/library/filters-white.png");
            }
        }
    }

    .documents {
        &__list {
            &.tab-pane,
            &.all-files {
                background-color: @gray-0;
            }

            &.tab-pane {
                height: 400px;
            }
            
            &.all-files {
                height: 570px;
            }
        }

        &__item {
            padding: 4px 5px;

            &.dashed-border {
                line-height: 29px;
                border-bottom: 1px dashed #e5e6e6;
            }
            
            .type-icon,
            .name {
                display: inline-block;
                vertical-align: bottom;
                color: @gray-4;
            }

            .name {
                max-width: 290px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }

            .type-icon {
                width: 30px;
                height: 30px;
                margin-right: 15px;
                background: none center center no-repeat;
                background-size: contain;
            }
        }

        &__header {
            margin-bottom: 5px;

            p {
                text-transform: uppercase;
                color: @blue-1;
                font-size: 14px;
                font-weight: bold;
            }

            .actions {
                font-size: 12px;

                span:first-child {
                    margin-right: 18px;
                }

                a {
                    color: @green-1;
                }

                i {
                    background-size: contain;
                    display: inline-block;
                    width: 15px;
                    height: 12px;
                    margin-right: 4px;
                }
            }
        }

        .pinned-docs {
            height: 120px;
            border: 1px solid @gray-3;
            background-color: @gray-1;
            margin-bottom: 5px;
        }
    }

    .filters {
        display: none;
        border-top: none;
        right: -170px;
        min-width: 180px;
        z-index: 9999;

        &,
        &__btn {
            position: absolute;
        }

        &,
        .panel-heading {
            border-radius: 0;
        }

        &.open {
            display: block;
        }

        &__btn {
            top: 0;
            right: 0;
            padding: 5px 12px 5px 0;
            height: 40px;
            cursor: pointer;
        }

        &__group {
            border: none;
            border-radius: 0;
            box-shadow: none;
            -webkit-box-shadow: none;

            &:not(:last-child) {
                border-bottom: 1px solid @gray-3;
            }

            a,
            .panel-heading {
                background-color: #ffffff;
            }

            a {
                font-weight: bold;

                i {
                    display: none;
                    float: right; 
                    font-size: 18px;
                    font-weight: bold;
                }

                &.collapsed i {
                    display: block;
                }
            }
        }

        .panel-body {
            border: none;
            padding-bottom: 0;
        }

        .close-filter {
            background-color: #5e5e5e;
            color: #ffffff;
            text-transform: uppercase;
        }

        img {
            width: 20px;
            float: right;
            cursor: pointer;
        }

        &__list {
            list-style: none;
        }

        &__item {
            margin-bottom: 5px;
            cursor: pointer;
            
            &.checked span {            
                background: url('../assets/images/library/checkbox.png') 0 -22px no-repeat;
            }

            span {
                display: inline-block;
                background: url('../assets/images/library/checkbox.png') 0 0 no-repeat;
                width: 19px;
                height: 19px;
                margin-right: 8px;
                vertical-align: middle;
            }     
        }

        label {
            font-size: 12px;
            font-weight: normal;
        }
    }
}

.newCollection,#newcollection{
  clear: left;
    width: 100%;
    height: 40px;
    background-color: #3b3c3d;
    cursor: pointer;
    a{
        color: #fff;
    }
    p{
        font-family: Roboto,sans-serif;
        padding-left: 20px;
        padding-top: 10px;
    }
}
