html {
    position: relative;
    min-height: 100%;
    font-family: 'Roboto', sans-serif;
}

body {
    /* Margin bottom by footer height */
    font-family: 'Roboto', sans-serif;
    margin-bottom: 60px;
}

p {
    margin: 0;
    padding: 0;
}

a {
    margin: 0;
    padding: 0;
}

ul {
    margin: 0;
    padding: 0;
}

*:focus {
    outline: 0;
}

code,
pre,
.pre {
    padding: 5px;
    margin: 10px 0;
    background-color: #EFEFEF;
    border: 1px solid #DADADA;
    border-radius: 3px;
}

pre {
    margin: 10px 0;
    padding: 5px;
}

.page-header {
    margin-top: 60px;
    &:first-child {
        margin-top: 20px;
    }
}

h2 {
    margin: 20px 0;
    color: #666;
}
/**
 * Navigation
 */

.navbar {
    margin-top: 20px;
    small {
        font-size: 60%;
    }
}
/**
 * Footer
 */

.footer {
    margin-top: 80px;
    color: #454545;
    font-family: @secondaryFont500;
    font-size: 10px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    /* Set the fixed height of the footer here */

    height: 30px;
}

.autocomplete {
    input {
        text-transform: capitalize;
    }

    ul.dropdown-menu {
        background-color: white;
        border: 1px solid #ccc;
        position: absolute;

        li {
            background-color: white;
            margin-bottom: 0 !important;
            height: 27px;
            text-transform: capitalize;

            a {
                font-family: "Roboto", sans-serif;
                font-size: 13px;
            }

            &.active {
                background-color: #428bca !important;
                a {
                    background-color: #428bca !important;
                    color: white;
                }
            }
        }
    }
}