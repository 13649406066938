/**
 * This is the main application stylesheet. It should include or import all
 * stylesheets used throughout the application as this is the only stylesheet in
 * the Grunt configuration that is automatically processed.
 */

/**
 * First, we include the Twitter Bootstrap LESS files. Only the ones used in the
 * project should be imported as the rest are just wasting space.
 */
 @import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';

@import 'variables';
@import 'utils';
@import 'common';

/**
 * Now that all app-wide styles have been applied, we can load the styles for
 * all the submodules and components we are using. 
 */

@import 'views';
@import 'library/_entry';
@import 'landing/_entry';
@import 'privacy/_entry';
